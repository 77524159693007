import { storage } from '@/utils/storage'

export default {
	namespaced: true,

	state: {
		isTutoSeen: true,
	},

	getters: {
		isTutoSeen(state) {
			return state.isTutoSeen
		}
	},

	actions: {
		isTutoSeen ({ commit, state }) {
			storage.getItem('tuto').then(res => {
        if (res !== null) {
					commit('isTutoSeen', res)
        }
        else {
					commit('isTutoSeen', false)
        }
      })
		}
	},

	mutations: {
    isTutoSeen (state, payload) {
      state.isTutoSeen = payload
      storage.setItem('tuto', payload)
    },
	}
}