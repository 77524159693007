<template>
  <div>
    <div class="u-wrapper-panel u-z-back" >
      <template v-for="(level, levelIndex) in levels">
        <transition :name="isTransitionNext || indexInterest !== -1 ? 'custom-fader-in' : 'custom-fader-out'">
          <pan
            :url="$device.isMobile ? level.image[0].mobile : level.image[0].url"
            v-if="level.index === data.index && level.image[0].mobile && level.image[0].url && indexInterest === -1"
            ref="pan"
            @onNext="onNext"
            @onPrev="onPrev"
            :pause="indexMedia !== -1"
          >
              <!-- image="/images/sound.png" -->
            <keypoints
               v-for="(entry, i) in level.keypointsMedia"
              image="/images/eye.png"
              :position="entry.position2d[0]"
              @click="indexMedia = i"
            />
            <keypoints
               v-for="(entry, i) in level.keypointsEasterEgg"
              image="/images/eye.png"
              :position="entry.position2d[0]"
              @click="indexInterest = i"
            />
          </pan>
        </transition>
      </template>
    </div>

    <!-- temp entries media -->
    <template v-for="(entry, i) in keypointsMedia">
      <transition name="t-fader">
        <popin-media
          v-if="i === indexMedia"
          :data="entry"
          @close="indexMedia = -1"
        />
      </transition>
    </template>
    <!-- temp entries media -->
    <template v-for="(entry, i) in keypointsEasterEgg">
      <transition name="custom-fader-out">
        <interest-zone
          @close="onCloseInterest"
          :levelIndex="data.index"
          v-if="indexInterest === i"
          :index="i"
          :data="entry"
        />
      </transition>
      <!-- <transition name="t-fader">
        <popin-easter
          :levelIndex="data.index"
          :index="i"
          @close="onCloseEaster"
        />
      </transition> -->
    </template>
    <transition name="t-fader">
      <popin-end v-if="isPopinEnd" @close="isPopinEnd = false" />
    </transition>
    <transition name="t-fader">
      <tutorial @end="endTutorial" v-if="!isTutorialSeen" />
    </transition>
  </div>
</template>

<script>
import Pan from '@/components/Pan'
import Keypoints from '@/components/Keypoints'
import PopinMedia from '@/components/PopinMedia'

import InterestZone from '@/components/InterestZone'

import PopinEnd from '@/components/PopinEnd'
import Tutorial from '@/components/Tutorial'

export default {
  name: 'Artwork',

  components: {
    Pan,
    InterestZone,
    Keypoints,
    PopinMedia,
    PopinEnd,
    Tutorial
  },

  data () {
    return {
      indexMedia: -1,
      indexInterest: -1,
      isTransition: false,
      isPopinEnd: false, 
      isTransitionNext: false,
    }
  },

  created () {
    this.$store.dispatch('global/isTutoSeen')
  },

  mounted () {
    window.addEventListener('keydown', this.onKeypress)
  },

  computed: {
    isTutorialSeen () {
      return this.$store.getters['global/isTutoSeen']
    },
    levels () {
      return this.$store.getters['data/levels']
    },
    data () {
      return this.$store.getters['data/levelBySlug'](this.$route.params.slug)
    },
    keypointsMedia () {
      return this.data.keypointsMedia
    },
    keypointsEasterEgg () {
      return this.data.keypointsEasterEgg
    },
    answerIndex () {
      return this.data.answerIndex
    }
  },

  methods: {
    endTutorial () {
      this.$store.commit('global/isTutoSeen', true)
    },

    onKeypress (e) {
      if (e.keyCode === 38) {
        this.onNext()
      }
      else if (e.keyCode === 40) {
        this.onPrev()
      }
      else if (e.keyCode === 37) {
        this.onLeft()
      }
      else if (e.keyCode === 39) {
        this.onRight()
      }
    },

    onPrev () {
      if (this.indexMedia === -1 && this.indexMedia === -1) {
        this.isTransitionNext = false
        const index = this.data.index
        this.$router.push({...this.$local('artwork'), params: {slug: this.$store.getters['data/prevLevel'](index).slug} })
      }
    },

    onNext () {
      if (this.indexInterest === -1 && this.indexInterest === -1) {
        this.isTransitionNext = true
        const index = this.data.index
        this.$router.push({...this.$local('artwork'), params: {slug: this.$store.getters['data/nextLevel'](index).slug} })
      }
    },

    onCloseInterest () {
      this.indexInterest = -1
      if (this.$store.getters['data/answered'] > 0 && this.$store.getters['data/answered'] === this.$store.getters['data/toAnswer']) {
        this.isPopinEnd = true
      }
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.indexInterest !== -1) {
      this.onCloseInterest()
    }
    else {
      next()
    }
  }
}
</script>

<style lang="stylus" scoped>

</style>