<template>
  <div style="position: fixed; width: 0; height: 0; overflow: hidden; top: -99px">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <symbol id="backward-arrow">
          <title>backward arrow</title>
          <path d="M15 27.7639L1.61803 1L28.382 1L15 27.7639Z"  fill="none" stroke-width="2"/>
        </symbol>
        <symbol id="forward-arrow">
          <title>forward arrow</title>
          <path d="M15 2.23607L28.382 29L1.61803 29L15 2.23607Z"  fill="none" stroke-width="2"/>
        </symbol>
        <symbol id="close" viewBox="0 0 24 24" width="24" height="24">
          <title>close</title>
          <path d="M24 8.21485e-07L0 24"  stroke-width="2"/>
          <path d="M8.21485e-07 -1.20156e-06L24 24"  stroke-width="2"/>
        </symbol>
        <symbol id="arrow-left" viewBox="0 0 13 21">
          <title>Icon Left</title>
          <path d="M12 1L1 11L12 20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </symbol>
        <symbol id="arrow-right" viewBox="0 0 13 21">
          <title>Icon Right</title>
          <path d="M0.999999 1L12 11L0.999999 20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </symbol>
        <symbol id="left-arrow-full">
          <title>left arrow</title>
          <path d="M2.23607 15L29 1.61803L29 28.382L2.23607 15Z"  fill="none" stroke-width="2"/>
        </symbol>
        <symbol id="right-arrow-full">
          <title>right arrow</title>
          <path d="M27.7639 15L1 28.382V1.61803L27.7639 15Z"  fill="none" stroke-width="2"/>
        </symbol>
        <symbol refY="center" id="minus">
          <title>minus</title>
          <path y="50%" d="M30 1H0"  stroke-width="2"/>
        </symbol>
        <symbol id="plus">
          <title>plus</title>
          <path d="M30 15L2.20535e-06 15M15 30L15 -6.55671e-07"  stroke-width="2"/>
        </symbol>
        <symbol id="pause">
          <title>pause</title>
          <path d="M1.5 0L1.5 30"  stroke-width="3"/>
          <path d="M16.5 0L16.5 30"  stroke-width="3"/>
        </symbol>
        <symbol id="play">
          <title>play</title>
          <path d="M0.75 23.25V0.75L23.25 12L0.75 23.25Z" />
        </symbol>
        <symbol id="see-more">
          <title>see more</title>
          <path d="M0.224468 10.5693C-0.0985706 10.9209 -0.0694766 11.4633 0.289383 11.7797L9.03884 19.4945C9.3738 19.7875 9.87627 19.7875 10.2112 19.4945L18.9607 11.7797C19.3195 11.4633 19.3486 10.9209 19.0256 10.5693C18.7009 10.2185 18.1489 10.1909 17.7883 10.5057L9.62503 17.704L1.46177 10.5057C1.12102 10.206 0.567414 10.1987 0.224468 10.5693ZM9.62503 7.41827L1.46177 0.219984C1.12102 -0.0797482 0.567413 -0.0869789 0.224468 0.283627C-0.098571 0.635162 -0.069477 1.17757 0.289382 1.49402L9.03884 9.20878C9.3738 9.50177 9.87627 9.50177 10.2112 9.20878L18.9607 1.49402C19.3195 1.17757 19.3486 0.635162 19.0256 0.283626C18.7009 -0.067212 18.1489 -0.0948571 17.7883 0.219983L9.62503 7.41827Z" />
        </symbol>
        <symbol id="separator">
          <title>separator</title>
          <path d="M1 0V30"  stroke-width="2"/>
        </symbol>
        <symbol width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" id="share">
          <title>share</title>
          <path d="M16.4286 12.75C15.2892 12.75 14.2861 13.2732 13.6321 14.073L7.00821 10.8852C7.08723 10.6011 7.14286 10.3081 7.14286 10C7.14286 9.69191 7.08723 9.39887 7.00821 9.11476L13.6321 5.92704C14.2861 6.72681 15.2892 7.25 16.4286 7.25C18.4011 7.25 20 5.71103 20 3.8125C20 1.91397 18.4011 0.375 16.4286 0.375C14.4561 0.375 12.8571 1.91397 12.8571 3.8125C12.8571 4.12059 12.9128 4.41363 12.9918 4.69774L6.3679 7.88546C5.71393 7.08569 4.71085 6.5625 3.57143 6.5625C1.59893 6.5625 0 8.10147 0 10C0 11.8985 1.59893 13.4375 3.57143 13.4375C4.71085 13.4375 5.71393 12.9143 6.3679 12.1145L12.9918 15.3023C12.9128 15.5864 12.8571 15.8794 12.8571 16.1875C12.8571 18.086 14.4561 19.625 16.4286 19.625C18.4011 19.625 20 18.086 20 16.1875C20 14.289 18.4011 12.75 16.4286 12.75ZM16.4286 1.75C17.6101 1.75 18.5714 2.67525 18.5714 3.8125C18.5714 4.94975 17.6101 5.875 16.4286 5.875C15.247 5.875 14.2857 4.94975 14.2857 3.8125C14.2857 2.67525 15.247 1.75 16.4286 1.75ZM3.57143 12.0625C2.38987 12.0625 1.42857 11.1373 1.42857 10C1.42857 8.86275 2.38987 7.9375 3.57143 7.9375C4.75299 7.9375 5.71429 8.86275 5.71429 10C5.71429 11.1373 4.75299 12.0625 3.57143 12.0625ZM16.4286 18.25C15.247 18.25 14.2857 17.3248 14.2857 16.1875C14.2857 15.0502 15.247 14.125 16.4286 14.125C17.6101 14.125 18.5714 15.0502 18.5714 16.1875C18.5714 17.3248 17.6101 18.25 16.4286 18.25Z" />
        </symbol>
      </defs>
    </svg>
  </div>
</template>


