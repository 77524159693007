<template>
  <popin :classPopin="$device.isMobile ? 'u-w6of8' : 'u-box-10by4 u-w6of8'" @close="$emit('close')">
    <div class="u-row" :class="{'u-wrapper-panel': !$device.isMobile}">
      <div class="u-w4of8  u-w8of8--sm u-full-height u-relative">
        <app-image
          class="u-full-width"
          :class="{'u-wrapper-panel u-fit-cover': !$device.isMobile}"
          v-if="data.image.length && !data.video.length"
          :id="data.image[0].id"
          :width="600"
          :height="1000"
        />
        <video-player
          :poster="data.image.length ? data.image[0].url : ''"
          :isWrapperPanel="!$device.isMobile"
          v-else-if="data.video.length"
          :forcePlay="true"
          :loop="true"
          :controls="false"
          :src="data.video[0].url"
        />
      </div>
      <div class="u-w4of8 u-w8of8--sm u-bg-blue u-white u-left u-middle u-pad-lg u-pad-md--md u-center" :class="{'u-full-height u-overflow-y-scroll u-invisible-scroll': !$device.isMobile}">
        <h1 class="t-h1 u-uppercase u-marg-b-lg">Félicitation !</h1>
        <div class="t-text u-marg-b-md u-uppercase" >{{ data.sousTire }}</div>
        <div class="t-text t-bold u-uppercase u-marg-b-lg" >{{ isLose ? data.lftTextLose : data.lftTextWin }}</div>
        <!-- <div class="t-text t-bold u-uppercase u-marg-b-xl" >{{ data.lftTextWin }}</div> -->
        <template v-if="!isLose">
          <div class="t-text u-uppercase">Code chèque-cadeau</div>
          <div class="t-text t-bold u-marg-b-md" >{{ code }}</div>
          <a :href="data.stLinkCta" target="_blank" class="t-link t-text--small u-marg-b-md u-marg-t-md u-uppercase">{{ data.stTextCta }}</a>
        </template>
      </div>
    </div>
  </popin>
</template>

<script>
import { api } from '@/store/interfaces/apiCode'
import { storage } from '@/utils/storage'

import Popin from '@/components/common/Popin'
import AppImage from '@/components/common/AppImage'
import VideoPlayer from '@/components/common/players/VideoPlayer'

// api.getToken()
// api.getCode()

export default {
  components: {
    Popin,
    AppImage,
    VideoPlayer
  },

  data () {
    return {
      code: '',
      isLose: true
    }
  },

  computed: {
    data () {
      return this.$store.getters['data/endpage']
    }
  },

  async created () {
    await storage.getItem('code').then(res => {
      if (res !== null || res === '') {
        this.code = res
      }
    })

    if (this.code === '') {
      await api.getToken().request
      api.getCode().then(res => {
        this.isLose = false
        this.code = res
      }).catch(() => {
        this.isLose = true
      })
    }

  },

  emits: ['close']
}
</script>

<style lang="stylus">

</style>