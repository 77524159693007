import { api } from '@/store/interfaces/apiCraft'
import { ref } from 'vue'

export default {
  namespaced: true,

  state: {
    levels: [],
    homepage: {},
    endpage: {},
    answered: 0,
    toAnswer: 0,
    loaded: false
  },

  getters: {
    levels: state =>{
      return state.levels
    },
    levelBySlug: state => slug => {
      return state.levels.find(level => level.slug === slug)
    },
    nextLevel: state => index => {
      index = (index + 1) % state.levels.length
      return state.levels[index]
    },
    prevLevel: state => index => {
      index =  index === 0 ? state.levels.length - 1 : index - 1
      return state.levels[index]
    },
    homepage: state =>{
      return state.homepage
    },
    endpage: state =>{
      return state.endpage
    },
    answered: state =>{
      return state.answered
    },
    toAnswer: state =>{
      return state.toAnswer
    },
    loaded: state =>{
      return state.loaded
    },
  },

  actions: {
    async homepage ({ commit, state }) {
      const { request, cache } = api.getHomepage()

      return new Promise(resolve => {
        cache.then(res => {
          if (res !== null) {
            commit('homepage', res)
            commit('loaded', true)
            resolve()
          }
        })

        request.then(res => {
          commit('homepage', res)
          commit('loaded', true)
          resolve()
        })
      })
    },
    async endpage ({ commit, state }) {
      const { request, cache } = api.getEndpage()

      return new Promise(resolve => {
        cache.then(res => {
          if (res !== null) {
            commit('endpage', res)
            commit('loaded', true)
            resolve()
          }
        })

        request.then(res => {
          commit('endpage', res)
          commit('loaded', true)
          resolve()
        })
      })
    },

    async levels ({ commit, state, dispatch }) {
      const { request, cache } = api.getEntries()

      return new Promise(resolve => {
        // cache.then(res => {
        //   if (res !== null) {
        //     commit('levels', res)
        //     commit('loaded', true)
        //     resolve()
        //   }
        // })

        request.then(async res => {
          commit('loaded', true)
          await dispatch('resizePan', res)

          resolve()
        })
      })
    },

    async resizePan ({ commit, state, dispatch }, payload) {
      this.promises = []
      this.fullPromises = []
      // get resized img for canvas
      // too big images will crash
      payload.forEach((level, index) => {
        // desktop
        level.image.forEach(img => {
          const { cache, request } = api.imgSize({id: img.id, height: 2048})
          this.promises.push(request)
          request.then((res) => {
            img.desktop = ref(res)
          })
        })
        // mobile
        level.image.forEach(img => {
          const { cache, request } = api.imgSize({id: img.id, height: 1024})
          this.promises.push(request)
          request.then((res) => {
            img.mobile = ref(res)
          })
        })
        level.keypoints.forEach(keypoint => {
          if (keypoint.lftImageFocused) {
            keypoint.lftImageFocused.forEach(img => {
              const { cache, request } = api.imgSize({id: img.id, height: 2048})
              this.fullPromises.push(request)
              request.then((res) => {
                img.desktop = ref(res)
              })
            })
            // mobile
            keypoint.lftImageFocused.forEach(img => {
              const { cache, request } = api.imgSize({id: img.id, height: 1024})
              this.fullPromises.push(request)
              request.then((res) => {
                img.mobile = ref(res)
              })
            })
          }
        })
      })

      this.fullPromises.push(...this.promises)

      await Promise.all(this.promises)
      commit('levels', payload)
      await Promise.all(this.fullPromises)
      commit('levels', payload)
    }
  },

  mutations: {
    loaded (state, payload) {
      state.loaded = payload
    },
    levels (state, payload) {
      state.toAnswer = 0

      payload.forEach((level, index) => {
        level.index = index
        level.keypointsMedia = []
        level.keypointsEasterEgg = []
        level.keypoints.forEach(keypoint => {
          if (keypoint.sectionHandle === 'lftEasterEgg') {
            level.keypointsEasterEgg.push({...keypoint})

            if (keypoint.lftIsEasterEgg) {
              state.toAnswer++
              keypoint.isAnswered = false
            }
          }
          else if (keypoint.sectionHandle === 'lftMedia') {
            level.keypointsMedia.push({...keypoint})
          }
        })
      })
      state.levels = payload
    },
    found (state, {levelIndex, keyPointindex}) {
      state.levels[levelIndex].keypointsEasterEgg[keyPointindex].isAnswered = true

      let totalAnswered = 0
      state.levels.forEach((level, index) => {
        level.keypointsEasterEgg.forEach(keypoint => {
          totalAnswered += keypoint.isAnswered ? 1 : 0
        })
      })

      state.answered = totalAnswered
    },
    homepage (state, payload) {
      state.homepage = payload
    },
    endpage (state, payload) {
      state.endpage = payload
    }
  }
}