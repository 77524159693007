import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { ApolloLink, concat } from 'apollo-link'
import { onError } from 'apollo-link-error'

import introspectionResult from './fragmentTypes.json'

import { emitter } from '@/utils/emitter'

export default class Apollo {
  constructor (uri, autoRefresh = true) {
    this.uri = uri
    this.token = process.env.VUE_APP_CRAFT_TOKEN

    this.getClient = this.getClient.bind(this)

    this.setup()

    if (autoRefresh) {
      emitter.on('TOKEN_REFRESH', this.setToken)
    }
  }

  setup () {
    const httpLink = new HttpLink({ uri: this.uri })

    this.authMiddleware = new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: {
          Authorization: `${this.token}`,
          ...this._headers
        }
      })

      return forward(operation)
    })

    const defaultOptions = {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore'
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all'
      }
    }
    const errorLink = onError(err => {
      if (err.networkError && err.networkError.statusCode === 403) {
        emitter.emit('TOKEN:NEEDSREFRESH')
      }
      if (err.graphQLErrors) {
        console.error(err.graphQLErrors)
      }

      err.forward(err.operation)
    })

    const links = errorLink.concat(httpLink)

    const fragmentMatcher = new IntrospectionFragmentMatcher({
      introspectionQueryResultData: introspectionResult,
    })

    this.client = new ApolloClient({
      defaultOptions,
      link: concat(this.authMiddleware, links),
      // Using a cache for blazingly
      // fast subsequent queries.
      cache: new InMemoryCache({ fragmentMatcher })
    })

    // this.client.clearStore()
  }

  getClient () {
    return this.client
  }

  set headers (value) {
    this._headers = {}
  }

  setToken (token) {
    this.token = token
    this.setup()
  }
}
