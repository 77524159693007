<template>
  <div class="navigation-bar u-row u-full-width" :class="$device.isMobile ? 'u-evenly': 'u-center'">
    <button @click="$emit('left')" class="navigation-bar__button">
      <svg class="navigation-bar__svg u-stroke-white">
        <use xlink:href="#left-arrow-full"/>
      </svg>
    </button>
    <button @click="$emit('right')" class="navigation-bar__button">
      <svg class="navigation-bar__svg u-stroke-white">
        <use xlink:href="#right-arrow-full"/>
      </svg>
    </button>
    <template v-if="completeControls">
      <div class="navigation-bar__button--separator">
        <svg class="navigation-bar__svg u-stroke-white">
          <use xlink:href="#separator"/>
        </svg>
      </div>
      <div @click="$emit('forward')" class="navigation-bar__button">
        <svg class="navigation-bar__svg u-stroke-white">
          <use xlink:href="#forward-arrow"/>
        </svg>
      </div>
      <div @click="$emit('backward')" class="navigation-bar__button">
        <svg class="navigation-bar__svg u-stroke-white">
          <use xlink:href="#backward-arrow"/>
        </svg>
      </div>
    </template>
    <div class="navigation-bar__button--separator">
      <svg class="navigation-bar__svg u-stroke-white">
        <use xlink:href="#separator"/>
      </svg>
    </div>
    <button @click="$emit('zoomIn')" class="navigation-bar__button">
      <svg class="navigation-bar__svg u-stroke-white">
        <use xlink:href="#plus"/>
      </svg>
    </button>
    <button @click="$emit('zoomOut')" class="navigation-bar__button">
      <svg class="navigation-bar__svg u-stroke-white navigation-bar__svg--small">
        <use xlink:href="#minus"/>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    completeControls: {
      type: Boolean,
      default: true
    }
  },

  emits: ['forward', 'backward', 'zoomIn', 'zoomOut',]
}
</script>

<style lang="stylus">
.navigation-bar
  position absolute
  bottom: 0

  &__button
    background transparent
    cursor pointer
    &--separator .navigation-bar__svg
      width 4px
      margin-left 25px
      margin-right 25px
  &__svg
    width 30px
    height 36px
    margin-top 20px
    margin-bottom 20px
    margin-left 10px
    margin-right 10px
    box-sizing: border-box
    &--small
      height 8px

+mq($until: 'tablet')
  .navigation-bar
    &__button
      padding 0 

      &--separator
        .navigation-bar__svg
          margin-left 15px
          margin-right 15px
    &__svg
      margin-top 15px
      margin-bottom 15px
      margin-left 8px
      margin-right 8px

      &--small
        height 6px

+mq($until: 345px)
  .navigation-bar
    &__button
      &--separator
        display none
</style>
