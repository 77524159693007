import * as BaseVirtualScroll from 'virtual-scroll'
import { Component } from 'shimmer'

import { clamp, mapper } from '@/utils/Maths'

const scrollDelta = 80

export class VirtualScroll extends Component {
  constructor (args) {
    super()

    this._onScroll = this._onScroll.bind(this)
    this.onUpdate = this.onUpdate.bind(this)
    this.onNext = this.onNext.bind(this)
    this.onPrev = this.onPrev.bind(this)
    this.onScroll = this.onScroll.bind(this)
    this.onSmoothScroll = this.onSmoothScroll.bind(this)
    this.onDestroy = this.onDestroy.bind(this)

    this.clamp = {
      min: args && args.clamp.min !== void 0 ? args.clamp.min : -Infinity,
      max: args && args.clamp.max !== void 0 ? args.clamp.max : Infinity
    }
    this.smooth = args && args.smooth !== void 0 ? args.smooth : .1
    // this.virtualScroll = new BaseVirtualScroll({el: args.el ? el : window})
    this.virtualScroll = new BaseVirtualScroll({useTouch: false})
    this.callbacksNext = []
    this.callbacksPrev = []
    this.callbacksScroll = []
    this.callbacksSmoothScroll = []
    this.smoothScroll = 0
    this.lastSmoothScroll = this.smoothScroll
    this.scroll = 0
    this.isNexting = false
    this.isPreving = false
    this.isactive = true
    this.isScrollEnable = true

    this.virtualScroll.on(this._onScroll)
  }

  _onScroll (e) {
    if (!this.isScrollEnable) {return}
    this.scroll -= (e.deltaY + e.deltaX) * .2
    this.scroll = clamp(this.scroll, this.clamp.min, this.clamp.max)

    this.callbacksScroll.forEach(callback => callback(this.scroll))

    if ((e.deltaY < -scrollDelta || e.deltaX < -scrollDelta)) {
      if (!this.isNexting) {
        this.callbacksNext.forEach(callback => callback())
        this.isNexting = true
      }
    }
    else if((e.deltaY > scrollDelta || e.deltaX > scrollDelta)) {
      if (!this.isPreving) {
        this.callbacksPrev.forEach(callback => callback())
        this.isPreving = true
      }
    } else {
      this.isNexting = false
      this.isPreving = false
    }
  }

  onUpdate () {
    this.smoothScroll += (this.scroll - this.smoothScroll) * this.smooth
    if (this.smoothScroll !== this.lastSmoothScroll) {
      this.callbacksSmoothScroll.forEach(callback => callback({
        value: this.smoothScroll,
        delta: this.lastSmoothScroll - this.smoothScroll,
        progress: mapper(this.smoothScroll, this.clamp.min, this.clamp.max, 0, 1)
      }))
    }
    this.lastSmoothScroll = this.smoothScroll
  }

  onNext (callback) {
    this.callbacksNext.push(callback)
  }

  onPrev (callback) {
    this.callbacksPrev.push(callback)
  }

  onScroll (callback) {
    this.callbacksScroll.push(callback)
  }

  onSmoothScroll (callback) {
    this.callbacksSmoothScroll.push(callback)
  }

  onDestroy () {
    this.virtualScroll.off(this._onScroll)
    this.destroy()
  }
}