<template>
  <div class="tutorials u-wrapper-panel u-z-middle">
    <img src="/images/background-tutorial.jpg" class="u-wrapper-panel u-fit-cover" />
    <transition-group name="t-fader">
      <template v-for="(tuto, index) in tutorials">
        <div class="u-wrapper-panel u-row u-middle u-center" v-if="introIndex === index" :key="index">
          <div class="u-w2of8">
            <div class="u-row u-flex u-center">
              <img :src="tuto.img" class="tutorials__image">
            </div>
            <div class="tutorials__text t-text t-text--small t-medium u-blue u-uppercase u-marg-t-md">
              {{ tuto.text }}
            </div>
          </div>
        </div>
      </template>
    </transition-group>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tutorials: [
        {
          img: '/images/tuto1.png',
          text: 'Click and drag to navigate'
        },
        {
          img: '/images/tuto2.png',
          text: 'SCROLL TO ZOOM IN OR OUT'
        }
      ],
      introIndex: 0
    }
  },

  mounted () {
    setTimeout(() => {
      this.introIndex = 1

      setTimeout(() => {
        this.$emit('end')
      }, 2000)
    }, 2000)
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.tutorials
  &:before
    content ''
    position absolute
    top 0
    left 0
    height 100%
    width 100%
    opacity .7
    background-color: $colors.white
  
  &__image
    width 55px
  &__text
    letter-spacing .05em
</style>