<template>
  <div class="u-z-back top-logos u-row u-middle u-full-width u-pad-md" :class="icons.length === 1 ? 'u-right' : 'u-between'">
    <transition name="t-fader">
      <div class="top-logos__logo" v-if="icons.includes('corner1')">
        <img class="u-full-width u-block" src="/images/corner_logo--2.png" alt="Louvre Lens">
      </div>
    </transition>
    <transition name="t-fader">
      <div class="top-logos__logo" v-if="icons.includes('mainLogo')">
        <router-link class="u-full-width u-block" :to="$local('intro')">
          <img class="u-full-width u-block" src="/images/main_logo_full--2.png" alt="Louvre Flashback Tour">
        </router-link>
      </div>
    </transition>
    <transition name="t-fader">
      <div class="top-logos__logo" v-if="icons.includes('corner2')">
        <a class="u-full-width u-block" target="_blank" href="https://www.primevideo.com/ref=atv_nb_logo">
          <img class="u-full-width u-block" src="/images/amazon--2.png" alt="Prime video">
        </a>
      </div>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'TopLogos',

    computed: {
      icons () {
        return this.$route.meta.visibleIcons ? this.$route.meta.visibleIcons : []
      }
    }
  }
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
    
.top-logos
  position absolute
  top 0
  left 0

  &__logo
    width 170px

    +mq($until: 'tablet')
      width 30%
</style>