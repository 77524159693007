<template>
  <div class="interest-zone u-wrapper-panel u-z-back">
    <pan
      :url="$device.isMobile ? data.lftImageFocused[0].mobile : data.lftImageFocused[0].url"
      :completeControls="false"
      :margin=".1"
      :pause="isFound"
      ref="pan"
    >
      <keypoints
        v-if="data.lftIsEasterEgg"
        :position="data.lftPosition2dFocused[0]"
        @click="isFound = true"
        :width="$device.height * .40"
        :isVisible="!!$route.query.debug"
      />
    </pan>
    <template v-if="!isPopinEnd">
      <transition name="t-fader">
        <popin-easter
          :levelIndex="levelIndex"
          :index="index"
          :data="data"
          v-if="isFound"
          @close="isFound = false"
        />
      </transition>
    </template>
    <template v-else>
      <transition name="t-fader">
        <popin-end
          v-if="isFound"
          @close="isFound = false"
        />
      </transition>
    </template>
    <div class="interest-zone__close u-z-middle">
      <svg @click="$emit('close')" class="u-full-width u-full-height u-fit-cover u-stroke-white u-fill-transparent u-cursor-pointer">
        <use xlink:href="#arrow-left"/>
      </svg>
    </div>
  </div>
</template>

<script>
import Pan from '@/components/Pan'
import PopinEaster from '@/components/PopinEaster'
import Keypoints from '@/components/Keypoints'
import PopinEnd from '@/components/PopinEnd'

export default {
  components: {
    Pan,
    PopinEaster,
    Keypoints,
    PopinEnd
  },

  computed: {
    isPopinEnd () {
      if (this.$store.getters['data/answered'] > 0 && this.$store.getters['data/answered'] === this.$store.getters['data/toAnswer']) {
        return true
      }
      return false
    }
  },

  data () {
    return {
      isFound: false
    }
  },

  props: {
    index: {
      type: Number,
      refault: 0
    },
    levelIndex: {
      type: Number,
      refault: 0
    },
    data: {
      type: Object,
      refault: {}
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

keypointColor = #42c5e8

.interest-zone
  &__close
    position absolute
    left: $spacings.md
    top calc(50% - 20px)
    width 50px
    height 50px
    border-radius 50%
    background-color keypointColor
    padding 12px
</style>