<template>
  <popin :classPopin="$device.isMobile ? 'u-w6of8' : 'u-w6of8 u-box-10by4'" @close="$emit('close')">
    <div class="u-row" :class="{'u-wrapper-panel': !$device.isMobile}">
      <div class="u-w5of8 u-w8of8--sm u-relative u-bg-white">
        <app-image
          class="u-full-width"
          :class="{'u-wrapper-panel u-fit-contain': !$device.isMobile}"
          v-if="data.image.length"
          :id="data.image[0].id"
          :width="600"
        />
        <video-player
          :isWrapperPanel="!$device.isMobile"
          v-else="data.video.length"
          :forcePlay="true"
          src="/videos/clap.mp4"
        />
      </div>
      <div class="u-w3of8 u-w8of8--sm u-bg-blue u-white u-flex u-left u-middle u-pad-lg u-pad-md--md" :class="{'u-full-height u-overflow-y-scroll u-invisible-scroll': !$device.isMobile}">
        <div class="u-full-width">
          <div class="t-h2 u-marg-b-md pane__title">FÉLICITATION !</div>
          <div class="t-text--small u-marg-b-md t-text--small u-uppercase" >{{ data.description }}</div>
          <div v-if="!isLast" class="t-bold t-text--small u-uppercase u-marg-b-md">Trouvez les autres Charlie pour remporter un chèque cadeau Amazon d’une valeur de 50€.</div>
          <audio-player class="u-full-width" v-if="data.lftSounds.length" :url="data.lftSounds[0].url" />
        </div>
      </div>
    </div>
  </popin>
</template>

<script>
import Popin from '@/components/common/Popin'
import AppImage from '@/components/common/AppImage'
import AudioPlayer from '@/components/common/players/AudioPlayer'
import VideoPlayer from '@/components/common/players/VideoPlayer'

export default {
  components: {
    Popin,
    AppImage,
    AudioPlayer,
    VideoPlayer
  },

  computed: {
    isLast () {
      return this.$store.getters['data/answered'] === this.$store.getters['data/toAnswer']
    }
  },

  props: {
    data: {
      type: Object,
      default: () => { return {} }
    },
    levelIndex: {
      type: Number,
      default: 0
    },
    index: {
      type: Number,
      default: 0
    },
  },

  created () {
    this.$store.commit('data/found', {levelIndex: this.levelIndex, keyPointindex: this.index})
  },

  emits: ['close']
}
</script>