import Apollo from '@/store/interfaces/Apollo'
import Axios from '@/store/interfaces/Axios'
import gql from 'graphql-tag'
import { storage } from '@/utils/storage'

const axios = new Axios(process.env.VUE_APP_OAUTH_TOKEN_API, false)

const apollo = new Apollo(process.env.VUE_APP_CODE_API, false)
// apollo.setToken('Bearer ' + 'HH94JD511Sf3IyznSkd1cSPaS2nRmI')
// apollo.headers = {
//   client_id: process.env.VUE_APP_OAUTH_ID
//   client_secret: process.env.VUE_APP_OAUTH_SECRET
//   code: process.env.VUE_APP_OAUTH_CODE
// }
// apollo.setToken('Bearer ' + process.env.VUE_APP_CRAFT_TOKEN)
let graphqlClient = apollo.client

class ApiCode {

  getToken () {
    const args = arguments

    let formData = new FormData()
    formData.append('username', process.env.VUE_APP_USERNAME)
    formData.append('password', process.env.VUE_APP_PASSWORD)
    formData.append('grant_type', 'password')

    const request = new Promise((resolve, reject) => {
      axios.post('', formData,
        {
          auth: {
            username: process.env.VUE_APP_CLIENT_ID,
            password: process.env.VUE_APP_CLIENT_SECRET
          }
        }
      )
      .then(res => {
        const result = res.data.access_token

        this.setToken(res.data.access_token)

        resolve(result)
        storage.setItem( 'token', result)
      })
      .catch(err => {
        console.error('Error getting token ', err)
      })
    })

    return { request }
  }

  setToken (token) {
    apollo.setToken('Bearer ' + token)
    graphqlClient = apollo.client
  }

  getCode () {
    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query MyQuery {
            getCode {
              code
            }
          }
        `
      })
      .then(res => {
        const result = res.data.getCode.code
        storage.setItem('code', result)
        resolve(result)
      })
      .catch(err => {
        reject()
        console.error('Error catching global params ', err)
      })
    })

    return request
  }
}

export const api = new ApiCode()