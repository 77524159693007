import { createWebHistory } from 'vue-router'

import Intro from './pages/Intro'
import Artwork from './pages/Artwork'
import messages from './locales'

const routes = [
  {
    path: '',
    name: 'intro',
    component: Intro,
    meta: {
      isFull: true,
      visibleIcons: ['corner1', 'corner2']
    }
  },
  {
    path: ':slug',
    name: 'artwork',
    component: Artwork,
    meta: {
      isFull: true,
      visibleIcons: ['mainLogo', 'corner2'],
    }
  },
  {
    path: ':slug',
    name: 'artwork',
    component: Artwork,
    meta: {
      isFull: true,
      visibleIcons: ['mainLogo', 'corner2'],
    }
  }
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'NotFound', 
  //   component: NotFound
  // }
]

let langRoutes = []

for (let i = 0; i < routes.length; i++)  {
  for (const locale of Object.keys(messages)) {
    const prefix = locale === 'en' ? '' : locale + '/'
    let route = {...routes[i]}
    route.path = '/' + prefix + route.path
    route.name = prefix + route.name
    route.meta = {
      ...routes[i].meta,
      locale: locale
    }

    langRoutes.push(route)
  }
}

export const router = {
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes: langRoutes
}
