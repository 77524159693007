<template>
  <div class="audio u-pad-y-xl u-pad-y-md--sm u-row u-center">
    <progress-bar :hasWave="true" @seek="seek" :progress="progress" :isPlaying="isPlaying" />
    <div @click.prevent="playSound" class="u-cursor-pointer u-inline-block u-left u-marg-l-md u-w1of12">
      <svg v-if="isPlaying" class="audio__control u-stroke-white">
        <use xlink:href="#pause"/>
      </svg>
      <svg v-else class="audio__control u-fill-white">
        <use xlink:href="#play"/>
      </svg>
      <audio ref="audio__sound" :src="url"></audio>
    </div>
  </div>
</template>

<script>
import ProgressBar from '@/components/common/players/ProgressBar'

export default {
  props: {
    url: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'audio'
    }
  },

  components: {
    ProgressBar,
  },

  data() {
    return {
      isPlaying: false,
      progress: 0,
      player: null
    }
  },
  mounted () {
    this.player = this.$refs.audio__sound
    this.player.addEventListener('loadedmetadata', this.onAudioData)
    this.player.volume = .7
  },

  methods: {
    onAudioData () {
      let sec= new Number()
      let min= new Number()

      sec = Math.floor( this.player.duration )    
      min = Math.floor( sec / 60 )
      min = min >= 10 ? min : '0' + min    
      sec = Math.floor( sec % 60 )
      sec = sec >= 10 ? sec : '0' + sec
      this.duration = min + ":"+ sec
    },
    playSound () {
      if (this.isPlaying) {
        this.player.pause()
        this.isPlaying = false
      } else {
        this.player.play()
        this.isPlaying = true
      }
    },
    onUpdate () {
      this.progress = this.player.currentTime / this.player.duration
    },
    seek (progress) {
      this.onUpdate()
      this.player.currentTime = this.player.duration * progress
    }
  }
}
</script>

<style lang="stylus">
.audio
  &__progress
    height: 3px
    background-color: #ffffff88

    &--fill
      height: 3px
      background-color: white
      transform-origin left
  &__control
    width 22px
    height 22px

</style>
