<template>
  <component
    :is="to.name ? 'router-link' : 'div'"
    :to="to"
    ref="root"
    class="keypoint u-cursor-pointer u-pointer-all"
    :class="{'is-active': isVisible}"
    @click="$emit('click')"
    :style="{
      left: `calc( ${position.x}% - ${width * .5}px )`,
      top: `calc( ${position.y}% - ${width * .5}px )`,
      height: `${width}px`,
      width: `${width}px`,
      padding: `${width * .2}px`,
      opacity: isVisible ? 1 : 0
    }"
  >
    <div class="keypoint__main u-box-1by1 u-full-width">
      <img v-if="image" :src="image" class="keypoint__main__image u-wrapper-panel u-fit-contain" />
    </div>
    <!-- <div class="keypoint__pulse" :class="{'is-active': !isSelected}"></div> -->
    <!-- <div class="keypoint__heart"></div> -->
  </component>
</template>

<script>
import Object2d from '@/plugins/Dom3d/mixin2d'

export default {
  emits: ['click'],

  props: {
    image: {
      type: String,
      default: ''
    },
    position: {
      type: Object,
      default: {x: 0, y: 0}
    },
    width: {
      type: Number,
      default: 40
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isVisible: {
      type: Boolean,
      default: true
    },
    to: {
      type: Object,
      default: () => {return {}}
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

keypointColor = #42c5e8

.keypoint
  border-radius 50%
  transition opacity .3s linear
  position absolute
  background-color: keypointColor
  
  &__main
    border-radius 50%

  // &__heart
  //   position absolute
  //   background: $colors.gold
  //   height 30%
  //   width 30%
  //   top 35%
  //   left 35%
  //   border-radius 50%

  &__pulse
    position absolute
    border solid 2px keypointColor
    height 200%
    width 200%
    top -50%
    left -50%
    border-radius 50%
    opacity 0
    will-change opacity transform

    &.is-active
      animation 2s ease-in-out-quad infinite pulse



@keyframes pulse
  0%
    transform: translateZ(0) scale(.5)
    opacity 0
  20%
    opacity 1

  100%
    transform: translateZ(0) scale(1)
    opacity 0
</style>