<template>
  <div
    id="main"
    ref="main"
    @mousemove="onMouseMove"
    :class="{'is-full': $route.meta.isFull}"
    class="u-full-width u-relative u-overflow-hidden u-antialiased u-bg-white"
  >
    <symbols-svg />
    <router-view v-slot="{ Component }">
      <transition name="t-fader">
        <component class="u-z-back u-wrapper-panel" v-if="routerReady" :is="Component" />
      </transition>
    </router-view>

    <top-logos />
    <bottom-links @legal="isLegals = true" @credits="isCredits = true"></bottom-links>
    <transition name="t-fader">
      <legals v-if="isLegals || isCredits" :isLegals="isLegals" :isCredits="isCredits" @close="isLegals = isCredits = false" />
    </transition>
  </div>
</template>

<script>
import App from './App'

import SymbolsSvg from '@/components/SymbolsSvg'

import TopLogos from '@/components/app/TopLogos'
import BottomLinks from '@/components/app/BottomLinks'

import Legals from '@/components/Legals'

export default {
  name: 'App',

  mixins: [App],

  components: {
    SymbolsSvg,
    BottomLinks,
    TopLogos,
    Legals
  },

  data () {
    return {
      routerReady: false,
      isLegals: false,
      isCredits: false
    }
  },

  created () {
    this.pointerevent = null
  },

  watch: {
    $route (to, from) {
      if (!from.name) {
        if (!to.query.debug) {
          this.$router.push(this.$local('intro'))
          requestAnimationFrame(() => {
            this.routerReady = true
          })
        }
        else {
          this.$store.dispatch('data/homepage')
          this.$store.dispatch('data/levels').then(() => {
            this.routerReady = true
          })
        }
      }
    }
  }
}
</script>

<style lang="stylus" src="./styles/theme.styl"/>

<style lang="stylus">
@import '~@/styles/settings/variables'

#main
  min-height 100vh
  color: $colors.white

  &.is-full
    position fixed
    top .5px
    bottom .5px
    left 0
    right 0
    overflow hidden
    min-height auto

.test
  position absolute
  height 200px
  width 200px
  background-color red
  
  &--1
    top 0
    left 0

  &--2
    bottom 0
    right 0

</style>