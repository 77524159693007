import axios from 'axios'
import { emitter } from '@/utils/emitter'

export default class Axios {
  constructor (uri, autoRefresh = true) {
    this.get = this.get.bind(this)
    this.post = this.post.bind(this)
    this.delete = this.delete.bind(this)
    this.put = this.put.bind(this)
    this.setToken = this.setToken.bind(this)

    this.uri = uri
    this.config = {}

    if (autoRefresh) {
      emitter.on('TOKEN_REFRESH', this.setToken)
    }
  }

  setup () {
    this.config.headers = {
      Authorization: `${this.token}`
    }
  }

  get (ressource, config) {
    return axios.get(this.uri + ressource, { ...this.config, ...config })
  }

  post (ressource, args, config) {
    return axios.post(this.uri + ressource, args, { ...this.config, ...config })
  }

  delete (ressource, config) {
    return axios.delete(this.uri + ressource, { ...this.config, ...config })
  }

  put (ressource, args, config) {
    return axios.put(this.uri + ressource, args, { ...this.config, ...config })
  }

  get headers () {
    return this.config.headers
  }

  setToken (token) {
    this.token = token
    this.setup()
  }
}
