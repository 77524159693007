import Apollo from '@/store/interfaces/Apollo'
import gql from 'graphql-tag'
import { storage } from '@/utils/storage'

const apollo = new Apollo(process.env.VUE_APP_CRAFT_API, false)
apollo.setToken('Bearer ' + process.env.VUE_APP_CRAFT_TOKEN)
const graphqlClient = apollo.client

const site = process.env.VUE_APP_CRAFT_WEBSITE

class APICraft {

  getHomepage () {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchHomepage {
            entry (site: "${site}", type: "lftHomepage") {
              ... on lftHomepage_lftHomepage_Entry {
                id
                sectionHandle
                title
                description
                lftCtaStart
                lftCtaTrailer
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.entry

        resolve(result)
        storage.setItem( `fetchHomepage_${JSON.stringify(args)}`, result)
      })
      .catch(err => {
        console.error('Error catching global params ', err)
      })
    })
    const cache = storage.getItem( `fetchHomepage_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }

  getEndpage () {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
          query fetchEndpage {
            entry (site: "${site}", section: "lftEndpage") {
              ... on lftEndpage_lftEndpage_Entry {
                id
                title
                sousTitre
                stLinkCta
                stTextCta
                lftTextLose
                lftTextWin
                image {
                  id
                  url
                }
                video {
                  id
                  url
                }
              }
            }
          }
        `
      })
      .then(res => {
        const result = res.data.entry

        resolve(result)
        storage.setItem( `fetchEndpage_${JSON.stringify(args)}`, result)
      })
      .catch(err => {
        console.error('Error catching global params ', err)
      })
    })
    const cache = storage.getItem( `fetchEndpage_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }

  getEntries () {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      graphqlClient.query({
        query: gql`
        query fetchEntries {
          entries (site: "${site}", section: "lftLevel") {
            ... on lftLevel_lftLevel_Entry {
              id
              slug
              image {
                url
                id
              }
              keypoints {
                id
                slug
                ... on lftMedia_lftMedia_Entry {
                  sectionHandle
                  title
                  sousTitre
                  position2d {
                    x
                    y
                  }
                  image {
                    url
                    id
                  }
                  lftSounds {
                    url
                  }
                  lftAttributes {
                    attribut
                  }
                  wysiwyg
                }
                ... on lftEasterEgg_lftEasterEgg_Entry {
                  sectionHandle
                  title
                  position2d {
                    x
                    y
                  }
                  lftIsEasterEgg
                  lftPosition2dFocused {
                    x
                    y
                  }
                  lftImageFocused {
                    url
                    id
                  }
                  lftSounds {
                    url
                  }
                  image {
                    url
                    id
                  }
                }
              }
            }
          }
        }
        `
      })
      .then(res => {
        const result = res.data.entries

        resolve(result)
        storage.setItem( `fetchEntries_${JSON.stringify(args)}`, result)
      })
      .catch(err => {
        console.error('Error catching global params ', err)
      })
    })
    const cache = storage.getItem( `fetchEntries_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }

  imgSize ({id, width, height}) {
    const args = arguments

    const request = new Promise((resolve, reject) => {
      let query = {}

      if (width && height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $width: Int, $height: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(width: $width, immediately: true, height: $height)
                }
              }
            }
          `,
          variables: { id, width, height }
        }
      }
      else if (width && !height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $width: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(width: $width, immediately: true)
                }
              }
            }
          `,
          variables: { id, width }
        }
      }
      else if (!width && height) {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument], $height: Int) {
              asset(id: $id) {
                ... on default_Asset {
                  url(height: $height, immediately: true)
                }
              }
            }
          `,
          variables: { id, height }
        }
      }
      else {
        query = {
          query: gql`
            query fetchImg ($id: [QueryArgument])  {
              asset(id: $id) {
                ... on default_Asset {
                  url
                }
              }
            }
          `,
          variables: { id }
        }
      }

      graphqlClient.query(query)
        .then(res => {
          resolve(res.data.asset.url)

          const result = res.data.asset.url

          resolve(result)
          storage.setItem( `fetchImg_${JSON.stringify(args)}`, result)
        })
        .catch(err => {
          console.error('Error catching global params ', err)
        })
    })

    const cache = storage.getItem(`fetchImg_${JSON.stringify(args)}`)

    return {
      request,
      cache
    }
  }
}

export const api = new APICraft()