<template>
  <div class="u-wrapper-panel">
    <div ref="pan" class="u-wrapper-panel u-cursor-grab" :class="{'is-grabbing': isPointerdown}" />
    <div class="pan__content u-pointer-none" ref="content">
      <slot></slot>
    </div>
    <navigation-bar
      class="u-z-back"
      :completeControls="completeControls"
      @forward="$emit('onNext')"
      @backward="$emit('onPrev')"
      @left="onLeft"
      @right="onRight"
      @zoomIn="onZoomIn"
      @zoomOut="onZoomOut"
    />
  </div>
</template>

<script>
import gsap from 'gsap'

import { clamp } from '@/utils/Maths'
import { Manager, Pinch } from 'hammerjs'
import { VirtualScroll } from '@/utils/VirtualScroll'

import { Application, Sprite, Loader } from 'pixi.js'

import NavigationBar from '@/components/NavigationBar'

export default {
  components: {
    NavigationBar
  },

  props: {
    url: {
      type: String,
      default: ''
    },
    // baseMaring / zoom
    margin: {
      type: Number,
      default: .3
    },
    //zoom applied on click
    zoom: {
      type: Number,
      default: .02
    },
    // zoom on scrool
    zoomMax: {
      type: Number,
      default: 3
    },
    pause: {
      type: Boolean,
      default: false
    },
    completeControls: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      isPointerdown: false
    }
  },

  created () {
    this.pointer = {
      position: {
        x: 0, y: 0
      },
      isDown: false
    }

    this.dom = {
      width: 0,
      height: 0
    }

    this.width = 0
    this.height = 0

    this.zoomMarge = {
      x: 0,
      y: 0
    }

    this.transform = {
      scale: 1,
      x: 0,
      y: 0
    }

    this.middle = {
      x: 0,
      y: 0
    }

    this.currentTransform = {
      x: 0,
      y: 0
    }

    this.width = 0
    this.height = 0

    this.dom = {
      width: 0,
      height: 0,
      el: null
    }
  },

  mounted () {
    this.baseScale = 0

    this.$refs.pan.addEventListener(this.$device.pointerdown, this.onPointerDown)
    this.$refs.pan.addEventListener(this.$device.pointerup, this.onPointerUp)
    this.$refs.pan.addEventListener(this.$device.pointermove, this.onPointerMove)

    this.application = new Application({autoStart: false, resizeTo: this.$refs.pan})
    this.$refs.pan.appendChild(this.application.view)

    this.application.loader.add('base', this.url, false)
    this.application.loader.load(this.onLoad)

    this.virtualScroll = new VirtualScroll({
      clamp: {
        min: -2000,
        max: 0,
      }
    })

    this.pinchScale = 0

    this.virtualScroll.onSmoothScroll(this.onScroll)
    // if (!this.$device.isTouch) {
    // }

    this.mc = new Manager(this.$refs.pan)
    this.pinch = new Pinch()
    this.mc.add([this.pinch])
    this.mc.on('pinch', this.onPinch)
  },

  unmounted () {
    setTimeout(() => {
      this.application.destroy()
      this.virtualScroll.onDestroy()
      gsap.killTweensOf(this.transform, 'scale')
    }, 2000)
  },

  methods: {
    onLoad (loader, resources) {
      this.texture = resources.base.texture
      this.sprite = new Sprite(this.texture)

      this.sprite.anchor.x = 0.5
      this.sprite.anchor.y = 0.5

      this.sprite.x = 200
      this.sprite.y = 200

      this.width = 500
      this.height = 500

      this.application.stage.addChild(this.sprite)
      this.onResize()
      this.application.start()
      this.application.renderer.resize(this.$device.width, this.$device.height)
    },

    zoomIn (value = 1500) {
      this.virtualScroll._onScroll({deltaY: 0, deltaX: value})
    },

    zoomOut (value = -1500) {
      this.virtualScroll._onScroll({deltaY: 0, deltaX: value})
    },

    onScroll (value) {
      if (this.pause) { return }
      this.baseScale = (1-value.progress) * this.zoomMax
      this.onResize()

      this.panHorizontal(0)
      this.panVertical(0)
    },

    onPinch (event) {
      const scale = event.scale
      this.zoomIn(-(this.pinchScale - scale) * 2000)
      console.log(-(this.pinchScale - scale) * 2000)
      this.pinchScale = scale
    },

    onPointerMove (event) {
      if (this.isPointerdown) {
        const pointerPos = this.$device.getPointerPosition(event)

        let delta = {
          x: this.pointer.position.x - pointerPos.x,
          y: this.pointer.position.y - pointerPos.y
        }

        this.panHorizontal(delta.x)
        this.panVertical(delta.y)

        this.pointer.position = pointerPos
      }
    },

    panHorizontal (value) {
      this.transform.x += value
      // temp
      this.transform.x = clamp(this.transform.x, this.minMaxTranslate.x.min, this.minMaxTranslate.x.max)
    },

    panVertical (value) {
      this.transform.y += value
      // temp
      this.transform.y = clamp(this.transform.y, this.minMaxTranslate.y.min, this.minMaxTranslate.y.max)
    },

    onPointerUp () {
      this.isPointerdown = false

      gsap.to(this.transform, {
        scale: 1,
        duration: .6,
        ease: 'power3.inOut',
        onUpdate: () => {
          this.sprite.scale.set(this.transform.scale, this.transform.scale)
        }
      })
    },

    onPointerDown (event) {
      this.pointer.position = this.$device.getPointerPosition(event)
      this.isPointerdown = true

      gsap.to(this.transform, {
        scale: 1 - this.zoom,
        duration: .4,
        ease: 'power3.inOut',
        onUpdate: () => {
          this.sprite.scale.set(this.transform.scale, this.transform.scale)
        }
      })
    },

    onUpdate () {
      if (this.sprite && !this.pause) {
        this.currentTransform.x += (this.transform.x - this.currentTransform.x) * .1
        this.currentTransform.y += (this.transform.y - this.currentTransform.y) * .1

        this.$refs.content.style.transform = `translate3d(${-this.currentTransform.x}px, ${-this.currentTransform.y}px, 0) scale(${this.transform.scale})`

        this.sprite.width = this.width * this.transform.scale
        this.sprite.height = this.height * this.transform.scale

        this.sprite.x = -this.currentTransform.x + this.middle.x
        this.sprite.y = -this.currentTransform.y + this.middle.y
      }
    },

    onResize () {
      if (!this.texture || !this.$refs.content) { return }
      const deviceRatio = this.$device.width / this.$device.height
      const contentRatio = this.texture.width / this.texture.height

      this.middle.x = this.$device.width * .5
      this.middle.y = this.$device.height * .5

      let width = 0
      let height = 0

      if (contentRatio >= deviceRatio) {
        width = this.$device.height * contentRatio
        height = this.$device.height
      }
      else {
        width = this.$device.width
        height = this.$device.width / contentRatio
      }

      this.playMarge = {
        x: width * (this.margin + this.baseScale),
        y: height * (this.margin + this.baseScale)
      }

      this.zoomMarge = {
        x: width * this.zoom,
        y: height * this.zoom,
      }

      this.width = width + this.zoomMarge.x + this.playMarge.x
      this.height = height + this.zoomMarge.y + this.playMarge.y

      this.dom.width = this.width
      this.dom.height = this.height

      this.$refs.content.style.width = this.dom.width + 'px'
      this.$refs.content.style.height = this.dom.height + 'px'
      this.$refs.content.style.top = (this.$device.height - this.dom.height) * .5 + 'px'
      this.$refs.content.style.left = (this.$device.width - this.dom.width) * .5 + 'px'
      // temp
      this.minMaxTranslate = {
        x: {
          min: ((this.$device.width - this.width) * .5 + this.zoomMarge.x),
          max: -((this.$device.width - this.width) * .5 + this.zoomMarge.x)
        },
        y: {
          min: ((this.$device.height - this.height) * .5 + this.zoomMarge.y),
          max: -((this.$device.height - this.height) * .5 + this.zoomMarge.y)
        },
      }
    },

    onLeft () {
      this.panHorizontal(-this.$device.width * .5)
    },

    onRight () {
      this.panHorizontal(this.$device.width * .5)
    },

    onZoomIn () {
      this.zoomIn()
    },

    onZoomOut () {
      this.zoomOut()
    },
  }
}
</script>

<style lang="stylus">
.pan
  &__content
    position absolute
</style>