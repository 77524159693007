<template>
  <div class="u-wrapper-panel u-row u-middle u-center u-pad-md u-cover u-white">
    <img class="u-wrapper-panel u-fit-cover" src="/images/home.jpg" />
    <div class="u-relative u-w4of8 u-w6of8--sm">
      <div class="u-row u-center">
        <h1 class="u-w4of8">
          <img class="u-full-width" src="/images/main_logo_full--2.png" alt="Louvre Flashback Tour">
        </h1>
      </div>
      <p class="u-marg-t-lg u-center t-text t-medium">{{ data.description }}</p>
      <div class="u-row u-center u-marg-t-xl u-pad-t-md">
        <div class="u-row u-w6of8 u-w8of8--sm u-row">
          <div class="u-w4of8 u-w8of8--sm u-center">
            <div class="intro__cta t-text t-medium t-link u-uppercase t-medium" @click="isPopinTrailer = true">{{ data.lftCtaTrailer }}</div>
          </div>
          <div class="u-w4of8 u-w8of8--sm u-center u-marg-t-lg--sm">
            <router-link
              v-if="target"
              class="intro__cta t-text t-medium t-link u-uppercase t-medium"
              :to="{...$local('artwork'), params: {slug: target.slug}}"
            >
              {{ data.lftCtaStart }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <transition name="t-fader">
      <popin v-if="isPopinTrailer" classPopin="u-box-16by9 u-w6of8" @close="isPopinTrailer = false">
        <video-player :muted="false" src="/videos/trailer.mp4" />
      </popin>
    </transition>
    <transition name="t-fader">
      <div v-if="!target" class="u-wrapper-panel u-z-front u-bg-white" />
    </transition>
  </div>
</template>

<script>
import Popin from '@/components/common/Popin'

import VideoPlayer from '@/components/common/players/VideoPlayer'

export default {
  name: 'Intro',

  components: {
    VideoPlayer,
    Popin
  },

  data () {
    return {
      isLoaded: false,
      isPopinTrailer: false
    }
  },

  computed: {
    data () {
      return this.$store.getters['data/homepage']
    },
    target () {
      const levels = this.$store.getters['data/levels']
      if (levels.length) {
        return levels[0]
      }
      else {
        return null
      }
    }
  },

  created () {
    this.$store.dispatch('data/homepage').then(async () => {
      this.isLoaded = true
      await this.$store.dispatch('data/levels')
      this.$store.dispatch('data/endpage')
    })
  }
}
</script>

<style lang="stylus">
.intro
  &__cta
    letter-spacing .08em
</style>