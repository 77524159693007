<template>
  <popin :classPopin="$device.isMobile ? 'u-w6of8' : 'u-w6of8 u-box-10by4'" @close="$emit('close')">
    <div class="u-row" :class="{'u-wrapper-panel': !$device.isMobile}">
      <div class="u-w5of8 u-w8of8--sm u-full-height u-relative u-bg-blue">
        <app-image
          class="u-full-width"
          :class="{'u-wrapper-panel u-fit-cover': !$device.isMobile}"
          v-if="data.image.length"
          :id="data.image[0].id"
          :width="600"
        />
      </div>
      <div class="u-w3of8 u-w8of8--sm u-bg-blue u-white u-flex u-column u-between u-left u-pad-xl u-pad-md--md" :class="{'u-full-height u-overflow-y-scroll u-invisible-scroll': !$device.isMobile}">
        <div class="t-h3 u-uppercase">{{ data.title }}</div>
        <div class="t-text--xsmall t-bold u-marg-b-md u-uppercase">{{ data.sousTitre }}</div>
        <div v-for="attr in data.lftAttributes" class="t-text--xsmall u-marg-b-md">{{ attr.attribut }}</div>
        <audio-player v-if="data.lftSounds.length" :url="data.lftSounds[0].url" />
        <p class="pane__text t-text--small" v-html="data.wysiwyg"></p>
      </div>
    </div>
  </popin>
</template>

<script>
import Popin from '@/components/common/Popin'
import AudioPlayer from '@/components/common/players/AudioPlayer'
import AppImage from '@/components/common/AppImage'

export default {
  props: {
    data: {
      type: Object,
      default: () => { return {} }
    }
  },

  components: {
    Popin,
    AudioPlayer,
    AppImage
  },

  emits: ['close']
}
</script>