<template>
  <div class="u-row u-right u-middle u-z-back u-pointer-none u-full-width links u-pad-md" :class="$device.isMobile && legalsVisible ? 'u-center--sm' : ''">
      <div v-if="legalsVisible" @click="$emit('legal')" class="t-text--small u-uppercase u-cursor-pointer u-pointer-all u-white u-pad-r-md">Mentions légales</div>
      <div v-if="legalsVisible" @click="$emit('credits')" class="t-text--small u-uppercase u-cursor-pointer u-pointer-all u-white u-pad-r-md">Crédits</div>
      <!-- <button class="links__share u-white u-bg-transparent">
        <svg class="links__share__svg u-fill-white">
          <use xlink:href="#share"/>
        </svg>
      </button> -->
    </div>
</template>

<script>
export default {
  computed: {
    legalsVisible () {
      return (!this.$device.isMobile || this.$route.name === 'intro')
    }
  },

  emits: ['legal', 'credits']
}
</script>

<style lang="stylus" scoped>
.links
  position absolute
  bottom 0
  left 0
  &__share__svg
    width 20px
    height 20px
    
</style>