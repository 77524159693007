<template>
  <div @click="seek" class="u-full-width u-pad-sm u-cursor-pointer" :class="{'u-pad-b-md u-pad-t-md': hasWave}" >
    <div ref="progressBar" class="bar-container u-row">
      <div
        v-for="i in boucingBarsNumber"
        :key="i"
        :style="getBarStyle(i)"
        class="bar"
        :class="{'wave-animation': hasWave, 'pause': !isPlaying }"
      />
    </div>
    <div
      class="bar-container--full u-row u-relative"
      :style="`clip-path: inset(-900% ${100 - progress * 100}% -900% -900%);`"
    >
      <div
        v-for="i in boucingBarsNumber"
        :key="i"
        :style="getBarStyle(i)"
        class="bar bar--fill"
        :class="{'wave-animation': hasWave, 'pause': !isPlaying}"
      />
    </div>
  </div>
</template>

<script>
import { randomRange } from '@/utils/Maths'

export default {
  props: {
    progress: {
      type: Number,
      required: true,
    },
    isPlaying: {
      type: Boolean,
      default: false,
    },
    hasWave: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    const nbr = 55
    return {
      boucingBarsNumber: nbr,
      bounceDurations: Array.from(
        { length: nbr },
        () => randomRange(.5, 1)
      ),
    }
  },
  methods: {
    seek(e) {
      const bounding = this.$refs.progressBar.getBoundingClientRect()
      const ratio = (e.clientX - bounding.left) / bounding.width
      this.$emit("seek", ratio)
    },
    getBarStyle(i) {
      if (!this.hasWave) {
        return {
          width: `${100 / this.boucingBarsNumber}%`
        }
      }

      return {
        width: `${(100 / this.boucingBarsNumber) * 0.5}%`,
        animationDuration: `${this.bounceDurations[i]}s`,
        marginLeft: `${(100 / this.boucingBarsNumber) * 0.25}%`,
        marginRight: `${(100 / this.boucingBarsNumber) * 0.25}%`
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.bar-container--full
  bottom 5px

.bar
  height 5px
  background-color #ffffff
  opacity 0.5

  &--fill
    opacity 1

.wave-animation
  will-change transform
  animation bounce 0ms -800ms linear infinite alternate

.pause
  animation-play-state paused

@keyframes bounce
  0% {
    transform scaleY(1)
  }

  100% {
    transform scaleY(5)
  }
</style>